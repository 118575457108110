import React, { useState, useEffect } from "react";

import {
    Card,
    Alert,
    Box,
    Popover,
    Tooltip,
    Typography,
    TextField,
    Grid,
    Stack,
    Autocomplete,
    IconButton,
    Button,
    FormControl,
    Paper,
    TableContainer,
    TablePagination,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Rating,
    CardContent,
} from "@mui/material";

import DBOpenPollResultsPrivate from "./DBOpenPollResultsPrivate.js";
import DBClosedPollResultsPrivate from "./DBClosedPollResultsPrivate.js";
import NewPollPrivate from "./NewPollPrivate";
import { db } from "../config/firebase.js";

function ProCorpPrivateAdmin({ userId, onLogin, onLogout, company, admin }) {
    // const { userId, db } = props;
    const [showStartNewPoll, setShowStartNewPoll] = React.useState(false);
    const [openPollResults, setShowOpenPollResults] = React.useState(true);
    const [closedPollResults, setShowClosedPollResults] = React.useState(false);

    const button2Color = "#f5f5f5";
    const buttonSelectedColor = "#af52bf";
    const [selectedButton, setSelectedButton] = useState(2)

    const handleStartNewPoll = () => {
        setShowStartNewPoll(!showStartNewPoll);
        setShowOpenPollResults(false);
        setShowClosedPollResults(false);

        if (!(selectedButton === 1)) {
            setSelectedButton(1);
        } else {
            setSelectedButton(0);
        }
    };

    const handleOpenPollResults = () => {
        setShowOpenPollResults(!openPollResults);
        setShowStartNewPoll(false);
        setShowClosedPollResults(false);
        if (!(selectedButton === 2)) {
            setSelectedButton(2);
        } else {
            setSelectedButton(0);
        }
    };

    const handleClosedPollResults = () => {
        setShowClosedPollResults(!closedPollResults);
        setShowStartNewPoll(false);
        setShowOpenPollResults(false);

        if (!(selectedButton === 3)) {
            setSelectedButton(3);
        } else {
            setSelectedButton(0);
        }
    };

    return (
        <>
            <Box marginTop={3} marginLeft="4px">
                <Stack spacing={4} direction="row">
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleStartNewPoll();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "60px",
                            marginLeft: "2px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 1 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 1 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            // backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        START NEW
                    </Button>
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleOpenPollResults();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "60px",
                            marginLeft: "15px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 2 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 2 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            //backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        OPEN POLLS
                    </Button>
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleClosedPollResults();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "60px",
                            marginLeft: "15px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 3 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 3 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            //backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        CLOSED POLLS
                    </Button>

                </Stack>
                {showStartNewPoll && <NewPollPrivate userId={userId} db={db} company={company} />}
                {openPollResults && <DBOpenPollResultsPrivate userId={userId} db={db} company={company} />}
                {closedPollResults && <DBClosedPollResultsPrivate userId={userId} db={db} company={company} />}
            </Box>
        </>
    );
}

export default ProCorpPrivateAdmin;
