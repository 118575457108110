import React, { useState, useEffect } from "react";
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Card,
  Alert,
  Box,
  Popover,
  Tooltip,
  Typography,
  TextField,
  Grid,
  Stack,
  Autocomplete,
  IconButton,
  Button,
  FormControl,
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Snackbar
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

import { collection, doc, where, query, updateDoc, deleteDoc, getDocs, addDoc } from "firebase/firestore"
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import { ThumbUp, ThumbDown } from "@mui/icons-material";
import { companyKeyprops, CXCategory, businessOptions, Business, CXCategoryTechCX, CXCategoryBanking, CXCategoryMedia, CXCategoryInsurance, CXCategoryFood } from "./MyData"
import defaultPic from "./enzo.jpg";
import { CriteriaDisplay } from "./Ratings";
import { db } from "../config/firebase"

function ProCorpCX({ userId, onLogin, onLogout }) {

  const [uId, setUId] = useState(userId);
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // Use 12-hour clock with AM/PM notation
  };
  const [showAlert, setShowAlert] = useState(true);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showDelDisallowAlert, setShowDelDisallowAlert] = useState(false);
  const [mouseRowChanged, setMouseRowChanged] = useState(false)
  const [dialog1, setDialog1] = useState(false);
  const [dialog2, setDialog2] = useState(false);
  const [likesUpdated, setLikesUpdated] = useState(false);
  const [opinion, setOpinion] = React.useState("");
  const [results, setResults] = React.useState([]);
  const [approvalInSelectedRow, setApprovalInSelectedRow] = useState([0]);
  const [dbDataUpdated, setDbDataUpdated] = useState(false);

  const [selectedBusiness, setSelectedBusiness] = React.useState(null);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [selectedCXCategory, setSelectedCXCategory] = React.useState(null);
  const [selectedCXCategoryLength, setSelectedCXCategoryLength] = React.useState(5);

  const [selectedRow, setSelectedRow] = useState(null);
  const [rowCount, setRowCount] = React.useState(0);
  const [showForm, setShowForm] = React.useState(false);
  const [likes, setLikes] = React.useState(0);
  const [sort, setSort] = useState({ column: "Count", order: "desc" });
  const [anchor, setAnchor] = useState(null);
  const [currentKeyprop, setCurrentKeyprop] = useState("Nothing here");
  const [currentPic, setCurrentPic] = useState(defaultPic);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [CXCategoryOptions, setCXCategoryOptions] = useState(CXCategory)

  const [ratings, setRatings] = React.useState({
    Company: null,
    C1: 3,
    C2: 2,
    C3: 5,
    C4: 1,
    C5: 3,
  });
  const [ratingsData, setRatingsData] = useState([]);

  const handleRatingChange = (criteria) => (event, ratingValue) => {
    setRatings((prevRatings) => ({ ...prevRatings, [criteria]: ratingValue }));
  };
  function getCXCategories(selectedBusiness) {
    let c1, c2, c3, c4, c5;
    switch (selectedBusiness) {
      case 'Tech CX':
        c1 = "UI Ease of Use"
        c2 = "UI Look/Appearance"
        c3 = null; ratings.C3 = 0;
        c4 = null; ratings.C4 = 0;
        c5 = null; ratings.C5 = 0;
        return { c1, c2, c3, c4, c5 }

      case 'Banking':
        c1 = "Charges"
        c2 = "Credit Cards/Loans"
        c3 = "Customer Service, Call Center, Virtual Asst."
        c4 = "ATM Availability"
        c5 = "Branch Facilities"
        return { c1, c2, c3, c4, c5 }

      case 'Insurance':
        c1 = "Charges"
        c2 = "Claim Payment"
        c3 = "Customer Service, Call Center, Virtual Asst."
        c4 = "Website/App"
        c5 = null
        return { c1, c2, c3, c4, c5 }

      case 'Food':
        c1 = "Crave Factor"
        c2 = "Deliciousness"
        c3 = "Restaurant Ambiance"
        c4 = "Delivery"
        c5 = "Price"
        return { c1, c2, c3, c4, c5 }

      case 'Media':
        c1 = "Bias - Agenda-driven"
        c2 = "Balance"
        c3 = "Credibiity"
        c4 = "Independence"
        c5 = "Content Quality/Delivery"
        return { c1, c2, c3, c4, c5 }

      default:
        c1 = "Quality"
        c2 = "Price"
        c3 = "Customer Service"
        c4 = "Responsiveness"
        c5 = "Premises/Facilities"
        return { c1, c2, c3, c4, c5 }
    }
  }
  const getRatings = () => {

    const { c1, c2, c3, c4, c5 } = getCXCategories(selectedBusiness)

    return (
      <div>
        <Stack marginTop="10px" spacing={1} direction="row">
          {c1 && (
            <CriteriaDisplay
              criteria={c1}
              value={ratings.C1}
              onChange={handleRatingChange("C1")}
            />
          )}
          {c2 && (
            <CriteriaDisplay
              criteria={c2}
              value={ratings.C2}
              onChange={handleRatingChange("C2")}
            />
          )}
          {c3 && (
            <CriteriaDisplay
              criteria={c3}
              value={ratings.C3}
              onChange={handleRatingChange("C3")}
            />
          )}
          {c4 && (
            <CriteriaDisplay
              criteria={c4}
              value={ratings.C4}
              onChange={handleRatingChange("C4")}
            />
          )}
          {c5 && (
            <CriteriaDisplay
              criteria={c5}
              value={ratings.C5}
              onChange={handleRatingChange("C5")}
            />
          )}
        </Stack>
      </div>
    );

  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async (inputIndex) => {
    const index = inputIndex + page * rowsPerPage;
    const newResults = [...results];
    const rowId = results[index].id;
    const name = results[index].Company;

    if (results[index].UserId !== userId && userId !== "rpaplus") {
      setShowDelDisallowAlert(true);
      // setDialog2(true);
    } else {
      try {
        // console.log ("row:", rowId)
        // Delete the document with the specified ID
        await deleteDoc(doc(db, "companyCXOverview", rowId));

        newResults.splice(index, 1);
        setResults(newResults);
        const overviewQuery = query(
          collection(db, "companyCXOverview"),
          where("Company", "==", name)
        );
        const overviewSnapshot = await getDocs(overviewQuery);
        if (overviewSnapshot.empty) {
          const detailsQuery = query(
            collection(db, "companyCXDetails"),
            where("Company", "==", name)
          );
          const detailsSnapshot = await getDocs(detailsQuery);

          detailsSnapshot.forEach(async (detailDoc) => {
            await deleteDoc(detailDoc.ref);
          });
        }
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    }
  };

  const sortedResults = results.sort((a, b) => {
    if (sort.order === "asc") {
      return a[sort.column] > b[sort.column] ? 1 : -1;
    } else {
      return b[sort.column] > a[sort.column] ? 1 : -1;
    }
  });

  const dataToDisplay = sortedResults.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const companyCXOverviewRef = collection(db, "companyCXOverview");

  const fetchData = async () => {

    try {
      const overviewSnapshot = await getDocs(companyCXOverviewRef);
      const overviewData = overviewSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setResults(overviewData);

      const detailsSnapshot = await getDocs(
        collection(db, "companyCXDetails")
      );
      const detailsData = detailsSnapshot.docs.map((doc) => ({
        id: doc.id,
        approval: doc.data()?.Approval,
        ...doc.data(),
      }));
      setRatingsData(detailsData);

      const approval = detailsData[0]?.approval;
      setApprovalInSelectedRow(approval);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateResultsApproval = () => {
    const updatedResults = results.map((row) => {
      const matchingRatingsData = ratingsData.find(
        (matchingRow) => matchingRow.Company === row.Company
      );
      return { ...row, Approval: matchingRatingsData.Approval };
    });

    setResults(updatedResults);
  };

  //Check this compile warning...
  useEffect(() => {
    setUId(userId);
    fetchData();
    if (!userId && showForm) {
      setShowForm(false)
    }
  }, [
    userId,
    showForm,
    likes,
    sort,
    mouseRowChanged,
    // selectedCompany,
    dbDataUpdated,
    approvalInSelectedRow,
    opinion,
    likesUpdated,
    rowCount,
  ]);

  async function addEntryToTable(collectionName, data) {
    try {
      const docRef = await addDoc(collection(db, collectionName), data);
      setDbDataUpdated(!dbDataUpdated); // toggle
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async function addOrUpdateDocument(collectionName, data, rowId) {
    try {
      let q;
      if (rowId) {
        q = query(collection(db, collectionName), where("id", "==", rowId));
      } else {
        q = query(
          collection(db, collectionName),
          where("Company", "==", data.Company)
        );
      }
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, data);
        setDbDataUpdated(!dbDataUpdated); // toggle
      } else {
        await addDoc(collection(db, collectionName), data);
        setDbDataUpdated(!dbDataUpdated); // toggle
      }
    } catch (error) {
      console.error("Error adding or updating document:", error);
    }
  }

  async function updateCXRating(company, CXRating) {
    const overviewQuery = query(
      collection(db, "companyCXOverview"),
      where("Company", "==", company)
    );
    const overviewSnapshot = await getDocs(overviewQuery);
    if (!overviewSnapshot.empty) {

      for (const doc of overviewSnapshot.docs) {
        await updateDoc(doc.ref, { CXRating: CXRating });
      }
      setDbDataUpdated(!dbDataUpdated); // toggle

    }
  }
  //*/
  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      selectedBusiness === null ||
      selectedCompany === null ||
      selectedCXCategory === null
    ) {
      return (
        <Alert severity="info">
          Need To Select Business, Company and Category, or Click ADD ENTRY again
          to go back
        </Alert>
      );
    }
    const rowToUpdate = ratingsData.find(
      (row) => row.Company === selectedCompany
    );
    const updateRow = { ...rowToUpdate };
    updateRow.CXCategoryOptions = CXCategoryOptions
    updateRow.Company = selectedCompany;
    updateRow.Business = selectedBusiness

    updateRow.c1CumVoteCounts = (updateRow.c1CumVoteCounts || 0) + 1;
    updateRow.c1CumVotes = (updateRow.c1CumVotes || 0) + ratings.C1;
    updateRow.c1Rating = Math.ceil(
      updateRow.c1CumVotes / updateRow.c1CumVoteCounts
    );
    updateRow.c2CumVoteCounts = (updateRow.c2CumVoteCounts || 0) + 1;
    updateRow.c2CumVotes = (updateRow.c2CumVotes || 0) + ratings.C2;
    updateRow.c2Rating = Math.ceil(
      updateRow.c2CumVotes / updateRow.c2CumVoteCounts
    );
    updateRow.c3CumVoteCounts = (updateRow.c3CumVoteCounts || 0) + 1;
    updateRow.c3CumVotes = (updateRow.c3CumVotes || 0) + ratings.C3;
    updateRow.c3Rating = Math.ceil(
      updateRow.c3CumVotes / updateRow.c3CumVoteCounts
    );
    updateRow.c4CumVoteCounts = (updateRow.c4CumVoteCounts || 0) + 1;
    updateRow.c4CumVotes = (updateRow.c4CumVotes || 0) + ratings.C4;
    updateRow.c4Rating = Math.ceil(
      updateRow.c4CumVotes / updateRow.c4CumVoteCounts
    );
    updateRow.c5CumVoteCounts = (updateRow.c5CumVoteCounts || 0) + 1;
    updateRow.c5CumVotes = (updateRow.c5CumVotes || 0) + ratings.C5;
    updateRow.c5Rating = Math.ceil(
      updateRow.c5CumVotes / updateRow.c5CumVoteCounts
    );
    const r1 = updateRow.c1Rating;
    const r2 = updateRow.c2Rating
    const r3 = updateRow.c3Rating
    const r4 = updateRow.c4Rating
    const r5 = updateRow.c5Rating

    const totalRatings = r1 + r2 + r3 + r4 + r5;
    const CXRat = totalRatings / selectedCXCategoryLength
    updateRow.CXRating = CXRat

    addOrUpdateDocument("companyCXDetails", updateRow, null);

    const companyCXOverviewData = {
      UserId: userId,
      Business: selectedBusiness,
      Company: selectedCompany,
      CXRating: updateRow.CXRating,
      CXCategory: selectedCXCategory,
      Chat: opinion,
      Count: likes,
      Date: new Date(),
    };
    addEntryToTable("companyCXOverview", companyCXOverviewData);

    updateCXRating(selectedCompany, updateRow.CXRating);

    resetSort(event);
    fetchData();
    setShowForm(false);
    setSelectedBusiness(null);
    setSelectedCompany(null);
    setSelectedCXCategory(null);
    setCXCategoryOptions(null);
    setOpinion("");
    setDbDataUpdated(!dbDataUpdated); // toggle;
  };

  const handleSort = (column) => {
    if (sort.column === column) {
      setSort({ column: column, order: sort.order === "asc" ? "desc" : "asc" });
    } else {
      setSort({ column: column, order: "desc" });
    }
  };

  const PopoverHandler = (event, result) => {
    if (popoverOpen) {
      setPopoverOpen(false);
      setAnchor(null);
      setCurrentKeyprop("Nothing Here");
      // setCurrentPic({ defaultPic });
    } else {
      setAnchor(event.currentTarget);
      let dataResult = companyKeyprops.find(
        (obj) => obj.company === result.company
      );
      setCurrentKeyprop(dataResult.keyprop);
      // setCurrentPic(dataResult.pic);
      setPopoverOpen(true);
    }
  };

  function resetSort(event) {
    setSort({ column: "Count", order: "desc" });
  }

  function handleBusinessChange(event, newValue) {
    setSelectedBusiness(newValue);
    setSelectedCompany(null);
  }

  function handleCXCategoryChange(event, newValue) {
    setSelectedCXCategory(newValue);
  }

  async function incrementCount(inputIndex) {
    const newResults = [...results];
    const index = inputIndex + page * rowsPerPage;
    newResults[index].Count += 1;
    setLikes(newResults[index].Count);

    setResults(newResults);
    setLikesUpdated(!likesUpdated); //toggle

    const docRef = doc(db, "companyCXOverview", newResults[index].id);
    // const rowId = newResults[index].id;
    try {
      await updateDoc(docRef, { Count: newResults[index].Count });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }

  async function decrementCount(inputIndex) {
    const newResults = [...results];
    const index = inputIndex + page * rowsPerPage;
    newResults[index].Count -= 1;
    setLikes(newResults[index].Count);

    setResults(newResults);

    setLikesUpdated(!likesUpdated); //toggle
    const docRef = doc(db, "companyCXOverview", newResults[index].id);
    // const rowId = newResults[index].id;
    try {
      await updateDoc(docRef, { Count: newResults[index].Count });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }

  function ratingCat(selectedBusiness) {
    const catObj = getCXCategories(selectedBusiness)
    let c1, c2, c3, c4, c5;

    c1 = catObj.c1;
    c2 = catObj.c2;
    c3 = catObj.c3;
    c4 = catObj.c4;
    c5 = catObj.c5;
    return [c1, c2, c3, c4, c5]
  }

  // const ratingCat = [
  //   "Approval",
  //   "RIRating",
  //   "PTSIRating",
  //   "TRating",
  //   "ConRating",
  //   "ComRating",
  // ];

  function ratingsLegend(selectedBusiness, index) {
    const cat = ratingCat(selectedBusiness)
    // console.log("selected Business parm:", selectedBusiness, index, ratingCat(selectedBusiness)[index])
    return cat[index]
  }
  // const ratingsLegend = [
  //   "Approval",
  //   "Resolves Issues",
  //   "Panders to Special Interests",
  //   "Trustworthy",
  //   "Conduct",
  //   "Competence",
  // ];

  const handleMouseEnter = (index) => {
    const currentPageIndex = page * rowsPerPage;
    const rowIndex = currentPageIndex + index;
    const rowData = sortedResults[rowIndex];
    setMouseRowChanged(!mouseRowChanged) //toggle
    setSelectedRow(rowData);
  };

  const handleMouseLeave = () => {
    setSelectedRow(null);
    //   setShowCard(false);
  };

  const handleCompanySelected = (newValue) => {
    setSelectedCompany(newValue);
    switch (selectedBusiness) {
      case "Tech CX":
        setSelectedCXCategoryLength(CXCategoryTechCX.length)
        return setCXCategoryOptions(CXCategoryTechCX)
      case "Banking":
        setSelectedCXCategoryLength(CXCategoryBanking.length)
        return setCXCategoryOptions(CXCategoryBanking)
      case "Media":
        setSelectedCXCategoryLength(CXCategoryMedia.length)
        return setCXCategoryOptions(CXCategoryMedia)
      case "Insurance":
        setSelectedCXCategoryLength(CXCategoryInsurance.length)
        return setCXCategoryOptions(CXCategoryInsurance)
      case "Food":
        setSelectedCXCategoryLength(CXCategoryFood.length)
        return setCXCategoryOptions(CXCategoryFood)
      default:
        return setCXCategoryOptions(CXCategory)
    }

  };

  function returnFormData() {
    return (
      <Grid item xs={12}>
        <Card
          elevation={10}
          style={{
            align: "left",
            justifyContent: "flex-start",
            marginBottom: "1em",
            marginLeft: "6px",

            width: "1300px",
          }}
        >
          <form justifyContent="flex-start" onSubmit={handleSubmit}>
            <Stack marginTop="10px" spacing={1} direction="row">
              <Autocomplete
                value={selectedBusiness}
                variant="filled"
                style={{ marginLeft: "6px", width: 250 }}
                color="default"
                options={Business}
                onChange={handleBusinessChange}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} label="Business" variant="filled" />
                )}
              />

              <Autocomplete
                value={selectedCompany}
                variant="filled"
                style={{ width: 250 }}
                color="default"
                options={businessOptions
                  .filter((businessOption) => businessOption.business === selectedBusiness)
                  .map((businessOption) => businessOption.name)
                  .flat()}
                onChange={(event, newValue) => handleCompanySelected(newValue)}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} label="Company" variant="filled" />
                )}
              />

            </Stack>

            {selectedCompany && (
              <Box>

                <Stack
                  marginTop="8px"
                  marginBottom="1px"
                  marginLeft="3px"
                  spacing={1}
                >
                  {getRatings()}
                  <Autocomplete
                    value={selectedCXCategory}
                    variant="filled"
                    style={{ marginLeft: "3px", marginTop: "1em", width: 250 }}
                    color="default"
                    options={CXCategoryOptions}
                    onChange={handleCXCategoryChange}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Chat, Category"
                        variant="filled"
                      />
                    )}
                  ></Autocomplete>

                  {selectedCXCategory && (
                    <FormControl marginLeft="3px">
                      <Stack marginTop="8px" marginLeft="0px" spacing={3}>
                        <TextField
                          label="Chat:"
                          style={{ marginLeft: "3px", width: "768px" }}
                          textAlign="justify"
                          inputProps={{ maxlength: 300, wrap: "hard" }}
                          variant="outlined"
                          multiline="true"
                          value={opinion}
                          onChange={(event) => setOpinion(event.target.value)}
                          helperText={`${opinion.length}/300`}
                        />
                      </Stack>
                    </FormControl>
                  )}
                </Stack>
              </Box>
            )}
            <br />
            <Button
              variant="contained"
              color="primary"
              style={{
                width: 80,
                margin: "0 0 0 4px",
                backgroundColor: "#5B76D7",
                color: "white",
                marginLeft: "3px",
              }}
              type="submit"
            >
              Submit
            </Button>
            <div style={{ height: "1em" }}></div>
          </form>
        </Card>
      </Grid>
    );
  }

  const handleRateChatClick = () => {

    if (userId) {
      setShowForm(!showForm);
    } else {
      setShowLoginAlert(true);
      // setDialog1(true);
    }
  };

  const MyDialog = ({ message, onClose }) => {
    return (
      <>

        <Snackbar
          open
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            marginTop: "100px"
          }}
        >
          <Alert onClose={handleClose} severity="error">
            <span style={{ fontSize: 16, fontStyle: 'italic' }}>Only the originator can delete...</span>
          </Alert>
        </Snackbar>
      </>
    );
  };

  const handleClose = () => {
    if (showLoginAlert) setShowLoginAlert(false);
    if (showDelDisallowAlert) setShowDelDisallowAlert(false);
    // if (dialog1) setDialog1(false);
    // if (dialog2) setDialog2(false);
  };

  function getMatchingCompanyData(Company) {
    const matchingRatingData = ratingsData.find(
      (data) => data.Company === Company
    );
    if (matchingRatingData) {
      return matchingRatingData;
    }
    return null;
  }
  const matchingRatingData = selectedRow
    ? getMatchingCompanyData(selectedRow.Company)
    : null;

  return (
    <>
      <Box marginTop={2}>
        <Grid container spacing={1} direction="row">
          <Grid item xs={6}>
            <Stack
              spacing={3}
              maxWidth="860px"
              direction="row"
              marginTop={2}
              marginBottom={3}
            >
              <Button
                variant="contained"
                backgroundcolor="#333"
                // endIcon={<SendIcon backgroundColor="grey" />}
                onClick={handleRateChatClick}
                style={{
                  border: "none",
                  width: "130px",
                  marginLeft: "8px",
                  borderRadius: 28,
                  color: "black",
                  backgroundColor: "#D1D1D1",
                }}
              >
                RATE, CHAT
              </Button>
              <br />
            </Stack>
            {showLoginAlert && (
              <Snackbar
                open
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                sx={{
                  marginTop: "100px"
                }}
              >
                <Alert onClose={handleClose} severity="error">
                  <span style={{ fontSize: 16, fontStyle: 'italic' }}>Login first...</span>
                </Alert>
              </Snackbar>
            )}
          </Grid>
          {showForm && returnFormData()}

          <Grid container spacing={9} direction="row">
            <Grid item xs={6}>
              <Card
                elevation={10}
                sx={{
                  width: "1350px",
                  marginLeft: "15px",
                  marginRight: "20px",
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{ overflow: "auto", marginTop: "0.5em" }}
                >
                  <Table
                    size="small"
                    sx={{ width: "90%", tableLayout: "fixed" }}
                  >
                    <TableHead
                      sx={{ marginTop: "1em", backgroundColor: "#5B76D7" }}
                    >
                      <TableRow dense>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            width: "25%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          UserId
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("UserId")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("UserId")}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            width: "30%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          Business
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("Business")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("Business")}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "break-word",
                            width: "30%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          <Tooltip title="Click for info & to exit">
                            <span>
                              Company
                              {sort.order === "asc" ? (
                                <ArrowDropUp
                                  sx={{ color: "white" }}
                                  onClick={() => handleSort("Company")}
                                />
                              ) : (
                                <ArrowDropDown
                                  sx={{ color: "white" }}
                                  onClick={() => handleSort("Company")}
                                />
                              )}
                            </span>
                          </Tooltip>
                        </TableCell>

                        <TableCell
                          sx={{
                            whiteSpace: "break-word",
                            width: "30%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          <Tooltip title="Rating: 0-5">
                            <span>
                              CX Rating
                              {sort.order === "asc" ? (
                                <ArrowDropUp
                                  sx={{ color: "white" }}
                                  onClick={() => handleSort("CXRating")}
                                />
                              ) : (
                                <ArrowDropDown
                                  sx={{ color: "white" }}
                                  onClick={() => handleSort("CXRating")}
                                />
                              )}
                            </span>
                          </Tooltip>
                        </TableCell>

                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            width: "50%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          CX Category
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("CXCategory")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("CXCategory")}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="justify"
                          sx={{
                            width: "100%",
                            wordWrap: "break-word",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          <Tooltip title="Sort by Likes">
                            <span>
                              Chat
                              {sort.order === "asc" ? (
                                <ArrowDropUp
                                  sx={{ color: "white" }}
                                  onClick={() => handleSort("Count")}
                                />
                              ) : (
                                <ArrowDropDown
                                  sx={{ color: "white" }}
                                  onClick={() => handleSort("Count")}
                                />
                              )}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align="justify"
                          sx={{
                            width: "50%",
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          Date
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("Date")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("Date")}
                            />
                          )}
                        </TableCell>
                        {/* <TableCell >Count</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{ marginTop: "1em", backgroundColor: "#F7F9F9" }}
                    >
                      {dataToDisplay.map((result, index) => (
                        <TableRow
                          hover
                          key={index}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <TableCell>{result.UserId}</TableCell>
                          <TableCell>{result.Business}</TableCell>
                          <TableCell
                            align="justify"
                            sx={{ whiteSpace: "nowrap" }}
                            onClick={(event) => PopoverHandler(event, result)}
                          >
                            {result.Company}
                            <Popover
                              open={Boolean(anchor)}
                              anchorEl={anchor}
                              onClose={() => PopoverHandler(null, null)}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <Card>
                                <Typography
                                  align="center"
                                  sx={{
                                    backgroundColor: "#F7F9F9",
                                    fontSize: 13,
                                    color: "#7286D3",
                                    wordWrap: "break-word",
                                    marginTop: 0,
                                    width: "150px",
                                    paddingBottom: "15px",
                                    // overflowY: "scroll",
                                    height: 40,
                                  }}
                                >
                                  {/* , */}
                                  {/* <img
                                    src={currentPic}
                                    width="35%"
                                    height="35%"
                                  /> */}
                                  <br></br>
                                  {currentKeyprop}
                                </Typography>
                              </Card>
                            </Popover>
                          </TableCell>
                          <TableCell align="center">
                            {result.CXRating}
                          </TableCell>
                          <TableCell
                            align="justify"
                            sx={{ whiteSpace: "break-word" }}
                          >
                            {result.CXCategory}
                          </TableCell>
                          <TableCell
                            align="justify"
                            sx={{ wordWrap: "break-word" }}
                          >
                            {result.Chat}
                          </TableCell>

                          <TableCell
                            align="justify"
                            sx={{ wordWrap: "nowrap" }}
                          >
                            {result.Date.toDate().toLocaleString(
                              "en-US",
                              dateOptions
                            )}
                          </TableCell>
                          {/* <TableCell>{result.Count}</TableCell> */}

                          <TableCell>
                            <Stack spacing={0.25} direction="row" align="top">
                              <IconButton onClick={() => incrementCount(index)}>
                                <ThumbUp />
                              </IconButton>
                              <IconButton onClick={() => decrementCount(index)}>
                                <ThumbDown />
                              </IconButton>
                              <IconButton onClick={() => handleDelete(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                            {showDelDisallowAlert && (
                              <MyDialog
                                message="Only the originator can delete..."
                                onClose={handleClose}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={sortedResults.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Card>
            </Grid>
            {selectedRow && (
              <Grid item xs={6}>
                <Card
                  sx={{
                    maxHeight: "440px",
                    // height: "90vh",
                    marginLeft: "520px",
                    width: "250px",
                    overflow: "auto",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            align="center"
                            style={{
                              height: "10px",
                              overflow: "hidden",
                              backgroundColor: "#5B76D7",
                            }}
                          >
                            <Typography color="white">
                              <h3>{selectedRow.Company}</h3>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ratingCat(selectedRow.Business).map((cat, index) => (
                          <>
                            <TableRow key={index}>
                              <TableCell
                                component="tr"
                                scope="row"
                                style={{ color: "#5B76D7" }}
                              >
                                {ratingsLegend(selectedRow.Business, index)}

                              </TableCell>
                              <TableCell sx={{ backgroundColor: "#F8F8F8" }}>

                                {matchingRatingData && matchingRatingData[`c${index + 1}Rating`] !== 0 && matchingRatingData[`c${index + 1}Rating`] != null
                                  ? matchingRatingData[`c${index + 1}Rating`]
                                  : null}
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!userId && (
          <Alert severity="info" action={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }>
            <span style={{ fontStyle: 'italic' }}>Login to Rate CX, Chat...</span>
          </Alert>
        )}
      </Box>
    </>
  );
}

export default ProCorpCX;
