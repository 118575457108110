import React, { useState, useEffect } from "react";

import {
    Card,
    Alert,
    Box,
    Popover,
    Tooltip,
    Typography,
    TextField,
    Grid,
    Stack,
    Autocomplete,
    IconButton,
    Button,
    FormControl,
    Paper,
    TableContainer,
    TablePagination,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Rating,
    CardContent,
} from "@mui/material";

import DBOpenPollResultsPrivate from "./DBOpenPollResultsPrivate";
import DBClosedPollResultsPrivate from "./DBClosedPollResultsPrivate";
import DBOpenRateResults from "./DBOpenRateResults";
import DBClosedRateResults from "./DBClosedRateResults";
import PollInvitesPrivate from "./PollInvitesPrivate";

function ProCorpPrivateUser(props) {
    const { userId, db, company, admin } = props;
    const [openPollResults, setShowOpenPollResults] = React.useState(true);
    const [showPollInvitations, setShowPollInvitations] = React.useState(false);
    const [closedRateResults, setShowClosedRateResults] = React.useState(false);
    const [closedPollResults, setShowClosedPollResults] = React.useState(false);
    const button2Color = "#f5f5f5";
    const buttonSelectedColor = "#af52bf";
    const [selectedButton, setSelectedButton] = useState(2)

    const handlePollInvitations = () => {
        setShowPollInvitations(!showPollInvitations);
        setShowOpenPollResults(false);
        setShowClosedPollResults(false);

        if (!(selectedButton === 1)) {
            setSelectedButton(1);
        } else {
            setSelectedButton(0);
        }
    };
    const handleOpenPollResults = () => {
        setShowOpenPollResults(!openPollResults);
        setShowClosedPollResults(false);
        setShowPollInvitations(false);

        if (!(selectedButton === 2)) {
            setSelectedButton(2);
        } else {
            setSelectedButton(0);
        }
    };

    const handleClosedPollResults = () => {
        setShowClosedPollResults(!closedPollResults);
        setShowOpenPollResults(false);
        setShowPollInvitations(false);

        if (!(selectedButton === 3)) {
            setSelectedButton(3);
        } else {
            setSelectedButton(0);
        }
    };

    return (
        <>
            <Box marginTop={3} marginLeft="4px">
                <Stack spacing={4} direction="row">
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handlePollInvitations();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "60px",
                            marginLeft: "2px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 1 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 1 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none"
                        }}
                    >
                        POLL INVITATIONS
                    </Button>
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleOpenPollResults();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "60px",
                            marginLeft: "15px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 2 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 2 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            //backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        OPEN POLLS
                    </Button>
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleClosedPollResults();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "60px",
                            marginLeft: "15px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 3 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 3 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            //backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        CLOSED POLLS
                    </Button>

                </Stack>
                {showPollInvitations && <PollInvitesPrivate userId={userId} db={db} company={company} admin={admin} />}
                {openPollResults && <DBOpenPollResultsPrivate userId={userId} db={db} company={company} admin={admin} />}
                {closedPollResults && <DBClosedPollResultsPrivate userId={userId} db={db} company={company} admin={admin} />}
            </Box>
        </>
    );
}

export default ProCorpPrivateUser;
